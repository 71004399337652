import React, { useEffect, Fragment } from 'react';
import { ConnectedNavLink } from 'Components/Util/Browser/ConnectedNavLink';
import { Switch, Route } from 'react-router';
import { fetchDeviceAction, clearDeviceAction } from 'Actions/Device/DeviceActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'Reducers/RootReducer';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import DeviceDetailsHeader from './DeviceDetailsHeader';
import styles from './DeviceDetailsPage.module.css';
import { DeviceDetailsPageApplications } from './DeviceDetailsPageApplications';
import { DeviceDetailsPageNetworkSettings } from './Network/DeviceDetailsPageNetworkSettings';
import { DeviceDetailsSummaryPage } from './DeviceDetailsSummaryPage';

type OwnProps = {
  serialNumber: string;
  systemId: string;
};

type Props = OwnProps & {
  device: EnrolledDevice | null;
  fetchDevice: (systemId: string, serialNumber: string, silent: boolean) => void;
  clearDevice: () => void;
};

export function _DeviceDetailsPage(props: Props) {
  useEffect(() => {
    props.fetchDevice(props.systemId, props.serialNumber, false);
    return props.clearDevice;
  }, [props.serialNumber, props.systemId]);

  if (!props.device) {
    return <Fragment />;
  }

  return (
    <div id="content" className={styles.devicePage}>
      <div className={styles.sideMenu}>
        <ul id="sidebarmenu" className="nav">
          <li>
            <ConnectedNavLink
              to={`/systems/${props.systemId}/devices/${props.serialNumber}/details`}
            >
              <i className="ico-sc-devices" />
              Device Information
            </ConnectedNavLink>
          </li>
          <li>
            <ConnectedNavLink
              to={`/systems/${props.systemId}/devices/${props.serialNumber}/applications`}
            >
              <i className="ico-sc-application" />
              Applications
            </ConnectedNavLink>
          </li>
          <li>
            <ConnectedNavLink
              to={`/systems/${props.systemId}/devices/${props.serialNumber}/network`}
            >
              <i className="ico-sc-network" />
              Network
            </ConnectedNavLink>
          </li>
        </ul>
      </div>

      <div className={styles.content}>
        <DeviceDetailsHeader device={props.device} systemId={props.systemId} />
        <div className={styles.paddedContent}>
          <Switch>
            <Route
              exact
              path="/systems/:systemId/devices/:serialNumber/details"
              render={() => (
                <DeviceDetailsSummaryPage
                  systemId={props.systemId}
                  serialNumber={props.serialNumber}
                />
              )}
            />
            <Route
              exact
              path="/systems/:systemId/devices/:serialNumber/applications"
              render={() => (
                <DeviceDetailsPageApplications
                  systemId={props.systemId}
                  serialNumber={props.serialNumber}
                  deviceId={props.device!.deviceId}
                />
              )}
            />
            <Route
              exact
              path="/systems/:systemId/devices/:serialNumber/network"
              render={() => (
                <DeviceDetailsPageNetworkSettings
                  systemId={props.systemId}
                  serialNumber={props.serialNumber}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state: RootState) {
  return {
    device: state.device.enrollment,
    system: state.system.info,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchDevice: (systemId: string, serialNumber: string, silent: boolean) =>
      dispatch(fetchDeviceAction(systemId, serialNumber, silent)),
    clearDevice: () => dispatch(clearDeviceAction()),
  };
}

export const DeviceDetailsPage = connect(mapStateToProps, mapDispatchToProps)(_DeviceDetailsPage);
