import { fetchDeviceAction } from 'Actions/Device/DeviceActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import { RootState } from 'Reducers/RootReducer';
import { call, put, select } from 'redux-saga/effects';
import { withNavigationGate } from 'Sagas/WithNavigationGate';
import {
  createApplicationDeploymentRequest,
  updateApplicationDeploymentRequest,
} from 'Services/AppDeploymentProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import { EnrolledDevice } from 'Models/Device/EnrolledDevice';
import { ApplicationInstallationFormState } from 'Reducers/Form/ApplicationInstallationFormReducer';
import { SUBMIT_APPLICATION_INSTALLATION_FORM } from 'Actions/ActionTypes';
import { clearApplicationInstallationFormAction } from 'Actions/Form/ApplicationInstallationFormActions';
import { assembleResourceModel } from 'Services/AppDeployment/DeploymentResourceParameterAssembler';

export function* submitApplicationInstallationFormSaga() {
  yield put(trackFetchAction('installApplication'));
  const token: string = yield select((state: RootState) => state.authentication.token);
  const device: EnrolledDevice = yield select((state: RootState) => state.device.enrollment);
  const form: ApplicationInstallationFormState = yield select(
    (state: RootState) => state.form.applicationInstallation
  );

  try {
    if (form.artifact === null) {
      throw 'The artifact is not present in the form.';
    }

    if (form.currentlyInstalled) {
      // App Update
      yield call(
        updateApplicationDeploymentRequest,
        token,
        device.systemId,
        device.deviceId,
        form.currentlyInstalled.id,
        form.artifact.version,
        assembleResourceModel(form.artifact.resources, form.parameters)
      );
    } else {
      // App installation
      yield call(
        createApplicationDeploymentRequest,
        token,
        device.systemId,
        device.deviceId,
        form.application!.id,
        form.artifact.version,
        assembleResourceModel(form.artifact.resources, form.parameters)
      );
      yield put(sendToastNotificationAction('success', 'The application will be installed soon.'));
    }

    yield put(clearApplicationInstallationFormAction());
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      yield put(sendToastNotificationAction('warning', e.message));
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while installing the application.'
        )
      );
    }
    throw e;
  } finally {
    yield put(clearFetchAction('installApplication'));
  }
  return device;
}

function* afterCompletion({ systemId, serialNumber }: EnrolledDevice) {
  yield put(fetchDeviceAction(systemId, serialNumber));
}

export function* watchSubmitApplicationInstallationForm() {
  yield withNavigationGate(
    SUBMIT_APPLICATION_INSTALLATION_FORM,
    submitApplicationInstallationFormSaga,
    afterCompletion
  );
}
