import config from 'react-global-configuration';
import { httpGet, httpDelete, httpPost } from 'Services/ServiceCaller';
import urljoin from 'url-join';
import { FirmwareInfo } from 'Models/Device/FirmwareInfo';
import { FirmwareUpgradeHistory } from 'Models/Device/FirmwareUpgradeHistory';
import { FirmwareUpgradeDetails } from 'Models/Device/FirmwareUpgradeDetails';
import { DeviceConfiguration, DeviceInventory } from '../../Models/Device/DeviceInfo';
import Constants from 'Constants';

export function getEnrolledDevicesRequest(
  token: string,
  systemId: string
): Promise<GetEnrolledDeviceResponse[]> {
  const url = urljoin(config.get(Constants.gatewayApi), `systems`, encodeURI(systemId), 'devices');

  return httpGet('GetEnrolledDevices', token, url).then(
    response => response._embedded.enrolledDevices
  );
}

export type GetEnrolledDeviceResponse = {
  systemId: string;
  deviceName: string;
  deviceId: string;
  serialNumber: string;
  enrollmentState: string;
  enrollmentTime: string;
  connectionStatus: string;
  lastConnectionStatusUpdate?: string;
  productCode: string;
};

export function getEnrolledDeviceRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<GetEnrolledDeviceResponse> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    `systems`,
    encodeURI(systemId),
    'devices',
    encodeURI(serialNumber)
  );
  return httpGet('GetEnrolledDevice', token, url);
}

export function deleteDeviceEnrollmentRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<void> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    `/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}`
  );
  return httpDelete('DeleteDeviceEnrollment', token, url);
}

export type DeviceEnrollmentRequest = {
  deviceName: string;
  serialNumber: string;
  activationCode: string;
};

export function enrollDeviceRequest(
  token: string,
  systemId: string,
  body: DeviceEnrollmentRequest
): Promise<GetEnrolledDeviceResponse[]> {
  const url = urljoin(config.get(Constants.gatewayApi), 'systems', encodeURI(systemId), 'devices');

  return httpPost('EnrollDevice', token, url, body, {
    'Content-Type': 'application/vnd.genetec.dm.deviceenrollment.create+json; v=1',
  });
}

export function getFirmwareInfoRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<FirmwareInfo> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    `/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}/firmware`
  );

  return httpGet('GetFirmwareInfo', token, url);
}

export function getDeviceInventoryRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<DeviceInventory> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    `/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}/inventory`
  );

  return httpGet('GetDeviceInventory', token, url);
}

export function getDeviceConfigurationRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<DeviceConfiguration> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    `/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}/configuration`
  );

  return httpGet('GetDeviceConfiguration', token, url);
}

export function getDeviceFirmwareUpgradeDetailsRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<FirmwareUpgradeDetails> {
  const url = urljoin(
    config.get(Constants.deviceIdentityApi),
    `/v1/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}/firmwareUpgradeStatus`
  );

  return httpGet('GetDeviceFirmwareUpgradeDetails', token, url).then(
    wrappedBody => wrappedBody.response
  );
}

export function getFirmwareUpgradeHistoryRequest(
  token: string,
  systemId: string,
  serialNumber: string
): Promise<FirmwareUpgradeHistory[]> {
  const url = urljoin(
    config.get(Constants.deviceIdentityApi),
    `/v1/systems/${encodeURI(systemId)}/devices/${encodeURI(serialNumber)}/firmwareUpgradeHistory`
  );

  return httpGet('GetDeviceFirmwareUpgradeHistory', token, url).then(
    wrappedBody => wrappedBody.response
  );
}
