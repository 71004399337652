import { createLiteralTypeCaster } from 'Utilities/TypeScriptUtils';

export type EnrolledDevice = {
  systemId: string;
  deviceId: string;
  deviceName: string;
  serialNumber: string;
  enrollmentState: EnrollmentState;
  enrollmentTime: Date;
  connectionStatus: ConnectionStatus;
  lastConnectionStatusUpdate?: Date;
  productCode: string;
};

const enrollmentStateValues = [
  'not_enrolled',
  'enrolling',
  'enrolled',
  'provisioned',
  'error_enrolling',
  'error_provisioning',
  'error_disenrolling',
  'provisioning',
] as const;
export type EnrollmentState = typeof enrollmentStateValues[number];
export const ToEnrollmentState = createLiteralTypeCaster<EnrollmentState>(enrollmentStateValues);

const authenticationTypeValues = ['x509', 'tpm'] as const;
export type AuthenticationType = typeof authenticationTypeValues[number];
export const toAuthenticationType = createLiteralTypeCaster<AuthenticationType>(
  authenticationTypeValues
);

const connectionStatusValues = ['connected', 'not_connected'] as const;
export type ConnectionStatus = typeof connectionStatusValues[number];
export const ToConnectionStatus = createLiteralTypeCaster<ConnectionStatus>(connectionStatusValues);
