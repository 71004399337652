import { FETCH_DEVICE } from 'Actions/ActionTypes';
import { FetchDeviceAction, receiveDeviceAction } from 'Actions/Device/DeviceActions';
import { clearFetchAction, trackFetchAction } from 'Actions/Loading/LoadingActions';
import { sendToastNotificationAction } from 'Actions/Notification/NotificationActions';
import {
  ToConnectionStatus,
  ToEnrollmentState,
  EnrolledDevice,
} from 'Models/Device/EnrolledDevice';
import { RootState } from 'Reducers/RootReducer';
import { all, call, put, select } from 'redux-saga/effects';
import { cancelOnNavigationEffect } from 'Sagas/CancelOnNavigationSaga';
import {
  getEnrolledDeviceRequest,
  GetEnrolledDeviceResponse,
} from 'Services/Device/DeviceIdentityProxy';
import { ServiceCallerError } from 'Services/ServiceCaller';
import {
  getDeploymentsRequest,
  getDeploymentRequest,
  GetDeploymentResponse,
} from 'Services/AppDeploymentProxy';
import {
  ApplicationDeploymentDetails,
  DeploymentStatus,
  ResourceStatus,
  WorkloadStatus,
} from 'Models/Application/ApplicationDeployment';

export function* fetchDeviceSaga(action: FetchDeviceAction) {
  if (!action.silent) {
    yield put(trackFetchAction('fetchDevice'));
  }
  try {
    const token: string = yield select((state: RootState) => state.authentication.token);

    const enrollmentResponse: GetEnrolledDeviceResponse = yield call(
      getEnrolledDeviceRequest,
      token,
      action.systemId,
      action.serialNumber
    );

    const deploymentsResponse: GetDeploymentResponse[] = yield call(
      getDeploymentsRequest,
      token,
      action.systemId,
      enrollmentResponse.deviceId
    );

    const deploymentResponseList: GetDeploymentResponse[] = yield all(
      deploymentsResponse.map(deployment =>
        call(
          getDeploymentRequest,
          token,
          action.systemId,
          enrollmentResponse.deviceId,
          deployment.id
        )
      )
    );

    yield put(
      receiveDeviceAction(
        assembleEnrollment(enrollmentResponse),
        assembleInstalledApplications(deploymentResponseList)
      )
    );
  } catch (e) {
    if (e instanceof ServiceCallerError) {
      if (e.code != 'deployment_not_found') {
        yield put(sendToastNotificationAction('warning', e.message));
      }
    } else {
      console.log(e);
      yield put(
        sendToastNotificationAction(
          'warning',
          'An error occurred while retrieving the device information.'
        )
      );
    }
  } finally {
    yield put(clearFetchAction('fetchDevice'));
  }
}

export function* watchFetchDevice() {
  yield cancelOnNavigationEffect(FETCH_DEVICE, fetchDeviceSaga);
}

function assembleEnrollment(response: GetEnrolledDeviceResponse): EnrolledDevice {
  return {
    systemId: response.systemId,
    deviceName: response.deviceName,
    deviceId: response.deviceId,
    serialNumber: response.serialNumber,
    enrollmentState: ToEnrollmentState(response.enrollmentState),
    enrollmentTime: new Date(response.enrollmentTime),
    connectionStatus: ToConnectionStatus(response.connectionStatus),
    lastConnectionStatusUpdate: response.lastConnectionStatusUpdate
      ? new Date(response.lastConnectionStatusUpdate)
      : undefined,
    productCode: response.productCode,
  };
}

function assembleInstalledApplications(
  responseList: GetDeploymentResponse[]
): ApplicationDeploymentDetails[] {
  return responseList.map(r => ({
    id: r.id,
    systemId: r.systemId,
    serialNumber: r.serialNumber,
    appId: r.appId,
    appVersion: r.appVersion,
    displayName: r.displayName,
    deploymentStatus: r.deploymentStatus as DeploymentStatus,
    deploymentStatusReason: r.deploymentStatusReason,
    lastUpdate: r.lastUpdate ? new Date(r.lastUpdate) : undefined,
    deployedResources: r.deployedResources.map(resource => ({
      name: resource.name,
      type: resource.type,
      displayName: resource.displayName,
      resourceStatus: resource.resourceStatus as ResourceStatus,
      workloadStatus: resource.workloadStatus as WorkloadStatus,
      parameters: resource.parameters,
    })),
  }));
}
