import config from 'react-global-configuration';
import urljoin from 'url-join';
import { httpGet, httpPost, httpDelete, httpPut } from './ServiceCaller';
import Constants from 'Constants';

export function getDeploymentsRequest(
  token: string,
  systemId: string,
  deviceId: string
): Promise<GetDeploymentResponse[]> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments'
  );

  return httpGet('ApplicationDeployment', token, url).then(
    response => response._embedded.deployments
  );
}

export type GetDeploymentResponse = {
  id: string;
  systemId: string;
  serialNumber: string;
  appId: string;
  displayName: string;
  appVersion: string;
  deploymentStatus: string;
  deploymentStatusReason: string;
  lastUpdate: string;
  deployedResources: {
    name: string;
    type: string;
    displayName: string;
    resourceStatus: string;
    workloadStatus: string;
    parameters: {
      key: string;
      value: string;
      isCustomValue: boolean;
    }[];
  }[];
};

export function getDeploymentRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string
): Promise<GetDeploymentResponse> {
  const url = urljoin(
    config.get(Constants.gatewayApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId)
  );
  return httpGet('ApplicationDeployment', token, url);
}

export function createApplicationDeploymentRequest(
  token: string,
  systemId: string,
  deviceId: string,
  appId: string,
  appVersion: string,
  resources: DeploymentResourceModel[]
): Promise<GetDeploymentResponse> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments'
  );

  return httpPost(
    'ApplicationDeployment',
    token,
    url,
    {
      appId,
      appVersion,
      resources,
    },
    { 'Content-Type': 'application/vnd.genetec.dm.applicationdeployment.create+json; v=1' }
  );
}

export function deleteApplicationDeploymentRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string
): Promise<void> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId)
  );
  return httpDelete('ApplicationDeployment', token, url);
}

export type DeploymentResourceModel = {
  name: string;
  parameters: {
    key: string;
    value: string;
  }[];
};

export function updateApplicationDeploymentRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string,
  appVersion: string,
  resources: DeploymentResourceModel[]
): Promise<GetDeploymentResponse> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId)
  );

  return httpPut(
    'ApplicationDeployment',
    token,
    url,
    {
      appVersion,
      resources,
    },
    {
      'Content-Type': 'application/vnd.genetec.dm.applicationdeployment.update+json; v=1',
      'If-Match': '*',
    }
  );
}

export function startApplicationRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string
): Promise<void> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId),
    'start'
  );

  return httpPost(
    'ApplicationDeployment',
    token,
    url,
    {},
    { 'Content-Type': 'application/vnd.genetec.application.deployment.start+json; v=1' }
  );
}

export function stopApplicationRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string
): Promise<void> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId),
    'stop'
  );
  return httpPost(
    'ApplicationDeployment',
    token,
    url,
    {},
    { 'Content-Type': 'application/vnd.genetec.application.deployment.stop+json; v=1' }
  );
}

export function restartApplicationRequest(
  token: string,
  systemId: string,
  deviceId: string,
  deploymentId: string
): Promise<void> {
  const url = urljoin(
    config.get(Constants.appDeployementApi),
    'systems',
    encodeURI(systemId),
    'devices',
    encodeURI(deviceId),
    'deployments',
    encodeURI(deploymentId),
    'restart'
  );
  return httpPost(
    'ApplicationDeployment',
    token,
    url,
    {},
    { 'Content-Type': 'application/vnd.genetec.application.deployment.restart+json; v=1' }
  );
}
